import { Inject, Injectable, Optional, TransferState, makeStateKey } from '@angular/core';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { LDOptions, LDUser } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { Observable, from, fromEvent, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { FeatureFlags, TempFeatureFlags } from '../domain/feature-flags.enum';
import { LAUNCH_DARKLY_BOOTSTRAP } from '../domain/launch-darkly-initialise.const';
import { LaunchDarklyClient } from '../feat-launch-darkly-client/launch-darkly-client.service';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private readonly featureSetKey = makeStateKey<LDFlagSet>('ld_flags');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private featureWatchers: { [key: string]: Observable<any> } = {};

  constructor(
    private readonly environment: Environment,
    private readonly platformService: PlatformService,
    private readonly launchDarklyClient: LaunchDarklyClient,
    @Optional() private readonly state: TransferState,
    @Optional() @Inject(LAUNCH_DARKLY_BOOTSTRAP) private readonly flags?: Promise<LDFlagSet>,
  ) {}

  public load(): Observable<boolean> {
    const { launchDarklyClientId } = this.environment;

    return (this.flags ? from(this.flags) : of(this.state?.get(this.featureSetKey, undefined))).pipe(
      map<LDFlagSet | undefined, LDOptions>((x) => (x ? { bootstrap: x } : {})),
      switchMap((options) => this.launchDarklyClient.loadClient$(launchDarklyClientId, options)),
      map((client) => {
        if (client && this.platformService.isPlatformServer) {
          this.state.set(this.featureSetKey, client.allFlags());
        }
        return !client;
      }),
    );
  }

  public getFeature<T = boolean>(key: FeatureFlags | TempFeatureFlags, defaultValue: T): T {
    const client = this.launchDarklyClient.getClient();

    return client?.variation(key, defaultValue) ?? defaultValue;
  }

  public getFeature$<T = boolean>(key: FeatureFlags | TempFeatureFlags, defaultValue: T): Observable<T> {
    if (this.featureWatchers[key]) {
      return this.featureWatchers[key];
    }
    const feature$ = this.launchDarklyClient.getClient$().pipe(
      switchMap((client) => {
        const initialValue: T = client?.variation(key, defaultValue) ?? defaultValue;

        if (client && this.platformService.isPlatformBrowser) {
          return fromEvent<T[]>(client, `change:${key}`).pipe(
            map((x) => x[1]), // array of previous/current
            startWith(initialValue),
          );
        }
        return of(initialValue);
      }),
    );

    this.featureWatchers[key] = feature$;

    return feature$;
  }

  async identify(user: LDUser): Promise<void> {
    const client = this.launchDarklyClient.getClient();

    if (client) {
      await client.identify(user);
    }
  }
}
