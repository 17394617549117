import { Pipe, PipeTransform } from '@angular/core';
import { RmaUrlsService } from '../rma-urls.service';

@Pipe({
  name: 'cdnUrl',
  standalone: true,
})
export class CdnUrlPipe implements PipeTransform {
  public constructor(private readonly rmaUrls: RmaUrlsService) {}

  public transform(value: string): string {
    return this.rmaUrls.cdnUrl(value);
  }
}
