import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { PlatformService } from '@rma/generic/util/environment';
import { cache } from 'decorator-cache-getter';
import { Observable, ReplaySubject, delay, of } from 'rxjs';
import { UserType } from '../domain/user-types.enum';
import { CurrentUser, CurrentUserType, SelectedContext } from '../feat-current-user/current-user.types';

const AgencyIndexKey = 'last_used_agency_index';
const ContextKey = 'rmaContext';

@Injectable({
  providedIn: 'root',
})
export class ContextSelectorService {
  private contextSubject$ = new ReplaySubject<SelectedContext | undefined>(1);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly platformService: PlatformService,
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
  ) {}

  public initialise(user: CurrentUser | null) {
    if (this.platformService.isPlatformServer) {
      return;
    }
    this.contextSubject$.subscribe((x) => {
      this.persistContext(x);
    });

    if (user === null || user.userType === CurrentUserType.Consumer) {
      this.contextSubject$.next(undefined);
      return;
    }
    const queryContext = this.route.snapshot.queryParamMap.get('context');

    if (queryContext) {
      const [type, code] = queryContext.split(':');

      if (type === UserType.Agent.toLowerCase() && code === user.agent?.agentCode) {
        this.contextSubject$.next({
          type: UserType.Agent,
          code: user.agent.agentCode,
          index: null,
        });
        return;
      }
      if (type === UserType.Agency.toLowerCase() && user.agencies && user.agencies.length) {
        const agency = user.agencies.find((x) => x.agencyCode === code);

        if (agency) {
          this.contextSubject$.next({
            type: UserType.Agency,
            code: agency.agencyCode,
            index: user.agencies.indexOf(agency),
          });
          return;
        }
      }
    }

    const context = this.getCurrentContext();

    if (context) {
      if (context.type === UserType.Agent && user.agent && context.code === user.agent.agentCode) {
        this.contextSubject$.next({
          type: UserType.Agent,
          code: user.agent.agentCode,
          index: null,
        });
        return;
      }
      if (context.type === UserType.Agency && user.agencies && user.agencies.length) {
        const agency = user.agencies.find((x) => x.agencyCode === context.code);

        if (agency) {
          this.contextSubject$.next({
            type: UserType.Agency,
            code: agency.agencyCode,
            index: user.agencies.indexOf(agency),
          });
          return;
        }
      }
    }

    const index = this.getSelectedAgency();

    if (index !== null && user.agencies && user.agencies.length) {
      const agency = user.agencies[index];

      if (agency) {
        this.contextSubject$.next({
          type: UserType.Agency,
          code: agency.agencyCode,
          index: index,
        });
        return;
      }
    }

    this.setDefaultContext(user);
  }

  public setDefaultContext(user: CurrentUser) {
    if (user.userType === CurrentUserType.Consumer) {
      this.contextSubject$.next(undefined);
    } else if (user.agent) {
      this.contextSubject$.next({
        type: UserType.Agent,
        code: user.agent.agentCode,
        index: null,
      });
    } else if (user.agencies && user.agencies.length > 0) {
      const agency = user.agencies[0];

      this.contextSubject$.next({
        type: UserType.Agency,
        code: agency.agencyCode,
        index: 0,
      });
    } else {
      this.contextSubject$.next(undefined);
    }
  }

  @cache
  public get selectedContext$(): Observable<SelectedContext | undefined> {
    return this.contextSubject$.asObservable();
  }

  public setContext(context: SelectedContext | undefined): Observable<boolean> {
    const changedContext = JSON.stringify(context) !== JSON.stringify(this.getCurrentContext());
    this.persistContext(context);
    this.contextSubject$.next(context);
    return changedContext ? of(changedContext).pipe(delay(200)) : of(changedContext);
  }

  private getSelectedAgency(): number | null {
    const agencyIndex = this.localStorage ? this.localStorage.getItem(AgencyIndexKey) : null;

    return agencyIndex === 'null' || !agencyIndex ? null : parseInt(agencyIndex, 10);
  }

  private getCurrentContext(): SelectedContext | undefined {
    const context = this.localStorage ? this.localStorage.getItem(ContextKey) : undefined;

    return context ? JSON.parse(context) : undefined;
  }

  private persistContext(selectedContext: SelectedContext | undefined) {
    if (selectedContext) {
      this.localStorage?.setItem(AgencyIndexKey, `${selectedContext.index}`);
      this.localStorage?.setItem(ContextKey, JSON.stringify(selectedContext));
    } else {
      this.localStorage?.removeItem(AgencyIndexKey);
      this.localStorage?.removeItem(ContextKey);
    }
  }
}
