// If you are adding something here, don't forget to add the key to: Infrastructure\public\RMA.Public.UI.Infrastructure\environment-settings.json

/**
 * This is a class and not an interface so we don't need use @Inject(Environment) everywhere we inject it
 */
export class Environment {
  public version!: string;

  public baseUrl!: string;
  public brokerUiUrl!: string;
  public domain!: string;

  public googleMapKey!: string;
  public countryCode!: 'AU' | 'NZ' | 'US';

  public datadomeJsKey?: string;
  public launchDarklyClientId!: string;
  public recaptchaSiteKey!: string;
  public securePrivacyApiKey?: string;
  public securePrivacyId?: string;

  public api!: {
    apiUrl: string;
    reviewsApiUrl: string;
    socialApiUrl: string;
    surveyApiUrl: string;
    billingApiUrl: string;
    emailApiUrl: string;
    findMyAgentApiUrl: string;
  };

  public social!: {
    facebookId: string;
    facebookUrl: string;

    twitterName: string;
    twitterUrl: string;

    linkedinUrl: string;
    instagramUrl: string;
  };

  public assets!: {
    cdnUrl: string;
    cloudinaryBase: string;
    cloudinarySuffix: string;
    staticUrl: string;
  };

  public tracking!: {
    hotjarId?: string;
    heapAppId?: string;
    wildjarId?: string;
    facebookPixelId?: string;
    googleTagManagerWebId?: string;
    googleTagManagerMegaphone?: string;
    googleAnalytics: {
      optimizeContainerId?: string;
      optimizeMeasurementId?: string;
      ga4?: string;
    };
    googleGlobalSiteTag?: {
      adConversionId?: string;
      phoneRevealAdConversionLabel?: string;
      callClickAdConversionLabel?: string;
      smsClickAdConversionLabel?: string;
      contactFormCompletionAdConversionLabel?: string;
    };
  };

  public identity!: {
    url: string;
    client: string;
    secret: string;
    jwtEnvironment: string;
  };

  public language!: {
    lokaliseAppId?: string;
    locale: string;
    supportedLocales?: string[];
    url: string;
    site?: string;
  };
}
