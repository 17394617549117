import { Pipe, PipeTransform } from '@angular/core';
import { RmaUrlsService } from '../rma-urls.service';

@Pipe({
  name: 'staticImageUrl',
  standalone: true,
})
export class StaticImageUrlPipe implements PipeTransform {
  public constructor(private readonly rmaUrls: RmaUrlsService) {}

  transform(value: string): string {
    return this.rmaUrls.imageUrl(value);
  }
}
