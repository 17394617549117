import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { OldAuthInterceptor } from './util-auth-interceptor/auth.interceptor';
import { JsonHeaderInterceptor } from './util-json-header-intercepter/json-header.interceptor';
import { TokenProviderService } from './util-token-provider';
import { TransferStateInterceptor } from './util-transfer-state-interceptor/transfer-state.interceptor';

function initialiseTokenProvider(tokenProviderService: TokenProviderService) {
  return () => tokenProviderService.initialise();
}

export function provideRmaHttpClient(hasAuth = true): EnvironmentProviders {
  return hasAuth
    ? makeEnvironmentProviders([
        { provide: HTTP_INTERCEPTORS, useClass: OldAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TransferStateInterceptor, multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: initialiseTokenProvider,
          deps: [TokenProviderService],
          multi: true,
        },
      ])
    : makeEnvironmentProviders([{ provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true }]);
}
