import { AgentGroupingType, AgentType, VerticalType } from '@rma/generic/domain/types';
import { UserType } from '../domain/user-types.enum';

export enum CurrentUserType {
  Consumer = 'Consumer',
  Agent = 'Agent',
  AgencyAdmin = 'AgencyAdmin',
  AgencyAdminAgent = 'AgencyAdminAgent',
}

export interface CurrentUser {
  userId: string;
  isImpersonating: boolean;
  userType: CurrentUserType;

  email: string;
  alias: string;
  firstname?: string;
  lastname?: string;
  name?: string;
  profileImage: string;

  timeZoneId: number;

  agent?: CurrentUserAgent;
  agencies?: CurrentUserAgency[];

  hasMultipleProfiles: boolean;
}

export interface CurrentUserAgent {
  agentCode: string;
  agentType: AgentType;
  agencyName?: string;
  profileUrl: string;
}

export interface CurrentUserAgency {
  agencyCode: string;
  name: string;
  profileImage: string;
  backgroundColour?: string;
  profileUrl: string;
  agentGroupingType?: AgentGroupingType;
}

export type CurrentUserContext = CurrentConsumerContext | CurrentAgentContext | CurrentAgencyContext;

export interface UserContext {
  hasMultipleProfiles: boolean;
  userType: UserType;
  userId: string;
  email: string;
  secondaryName?: string;
  firstname?: string;
  lastname?: string;
  name: string;
  timeZoneId: number;

  profileImage: string;
  backgroundColour?: string;

  profileCode?: string;
  profileUrl?: string;
  dashboardUrl?: string;
  isImpersonating: boolean;
}

export interface CurrentConsumerContext extends UserContext {
  userType: UserType.Consumer;
}

export interface CurrentAgentContext extends UserContext {
  userType: UserType.Agent;
  profileCode: string;
  profileUrl: string;
  dashboardUrl: string;

  verticalType: VerticalType;
}

export interface CurrentAgencyContext extends UserContext {
  userType: UserType.Agency;
  profileCode: string;
  profileUrl: string;
  dashboardUrl: string;

  verticalType: VerticalType;
  isTeam: boolean;
}

export interface SelectedContext {
  code: string;
  type: UserType.Agent | UserType.Agency;
  index: number | null; // legacy integration
}
