import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, StateKey, TransferState, makeStateKey } from '@angular/core';

import { PlatformService } from '@rma/generic/util/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TransferStateInterceptor implements HttpInterceptor {
  private supportedOperators = ['GET', 'DELETE', 'PUT']; //idempotent actions

  constructor(
    private readonly transferState: TransferState,
    private readonly platformService: PlatformService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.supportedOperators.includes(request.method)) {
      return next.handle(request);
    }

    if (request.responseType !== 'json') {
      return next.handle(request);
    }

    const stateKey: StateKey<string> = makeStateKey<string>(`${request.urlWithParams}|${request.method}`);

    if (this.platformService.isPlatformServer) {
      return next.handle(request).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            this.transferState.set(stateKey, event.body);
          }
        }),
      );
    }

    const transferStateResponse = this.transferState.get<unknown>(stateKey, null);
    if (transferStateResponse) {
      const response = new HttpResponse({ body: transferStateResponse, status: 200 });
      // Removing temporarily to see if it causes problems : Stones 2022-10-17
      // this.transferState.remove(stateKey);
      return of(response);
    } else {
      return next.handle(request);
    }
  }
}
