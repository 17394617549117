import { inject, Injectable } from '@angular/core';
import { AgentGroupingType, AgentType, VerticalType } from '@rma/generic/domain/types';
import { PlatformService } from '@rma/generic/util/environment';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import { cache } from 'decorator-cache-getter';
import { Observable, ReplaySubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { UserType } from '../domain/user-types.enum';
import { ContextSelectorService } from '../feat-context-selector/context-selector.service';
import { CurrentUserApiService } from './current-user-api.service';
import { CurrentUser, CurrentUserContext, SelectedContext } from './current-user.types';
import { TokenProviderService } from '@rma/generic/util/http';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private readonly apiService = inject(CurrentUserApiService);
  private readonly contextSelector = inject(ContextSelectorService);
  private readonly tokenProviderService = inject(TokenProviderService);

  private readonly currentUserSubject$ = new ReplaySubject<CurrentUser | null>(1);

  async initialise(): Promise<void> {
    //If there is no token, there is no need to hit the /me endpoint
    const doNotAttemptAuth = !this.tokenProviderService.hasUserToken();

    if (doNotAttemptAuth) {
      //This breaks angulartics if not included
      this.currentUserSubject$.next(null);
    }

    const userSource$ = doNotAttemptAuth ? of(null) : this.reloadUser();

    await firstValueFrom(userSource$.pipe(tap((x) => this.contextSelector.initialise(x))));
  }

  public get currentUser$(): Observable<CurrentUser | null> {
    return this.currentUserSubject$.asObservable();
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this.currentUserSubject$.pipe(map((x) => !!x));
  }

  @cache
  public get currentUserContext$(): Observable<CurrentUserContext | null> {
    return combineLatest([this.currentUserSubject$, this.contextSelector.selectedContext$]).pipe(
      map(([user, selectedContext]) => this.mapUserProfile(user, selectedContext)),
      rmaShareReplay(),
    );
  }

  public reloadUser(): Observable<CurrentUser | null> {
    const reloadedUser$ = this.apiService.getCurrentUser().pipe(
      catchError((e) => {
        console.warn('Auth check failed', e);
        return of(null);
      }),
      tap((x) => this.currentUserSubject$.next(x)),
      rmaShareReplay(),
    );
    reloadedUser$.pipe(take(1)).subscribe(); // ensure it happens
    return reloadedUser$;
  }

  public onLogout() {
    this.currentUserSubject$.next(null);
  }

  private mapUserProfile(user: CurrentUser | null, selectedContext: SelectedContext | undefined): CurrentUserContext | null {
    if (!user) {
      return null;
    }
    const context: CurrentUserContext = {
      hasMultipleProfiles: user.hasMultipleProfiles,
      userId: user.userId,
      email: user.email,
      name: user.name ?? user.alias,
      firstname: user.firstname,
      lastname: user.lastname,
      timeZoneId: user.timeZoneId,
      profileImage: user.profileImage,
      userType: UserType.Consumer,
      isImpersonating: user.isImpersonating,
    };

    if (!selectedContext) {
      return context; // is consumerer
    }

    if (selectedContext.type === UserType.Agency) {
      const agency = user.agencies?.find((x) => x.agencyCode === selectedContext.code);

      if (agency) {
        return {
          ...context,
          userType: UserType.Agency,
          name: agency.name,
          profileImage: agency.profileImage,
          backgroundColour: agency.backgroundColour,
          profileCode: agency.agencyCode,
          profileUrl: agency.profileUrl,
          dashboardUrl: '/dashboard/agency',
          verticalType: agency.agentGroupingType === AgentGroupingType.MortgageBrokerTeam ? VerticalType.Finance : VerticalType.Sales,
          isTeam: agency.agentGroupingType === AgentGroupingType.Team,
        };
      }

      // config was bad
      this.contextSelector.setDefaultContext(user);
    }

    if (user.agent) {
      return {
        ...context,
        userType: UserType.Agent,
        secondaryName: user.agent.agencyName,
        profileCode: user.agent.agentCode,
        profileUrl: user.agent.profileUrl,
        dashboardUrl: '/dashboard/agent',
        verticalType: user.agent.agentType === AgentType.RealEstate ? VerticalType.Sales : VerticalType.Finance,
      };
    }
    // config was bad
    this.contextSelector.setDefaultContext(user);

    return context;
  }
}
