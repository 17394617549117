import { Injectable } from '@angular/core';
import { Environment } from '../domain/environment.model';
import { RmaApiUrlType } from './rma-api-url/rma-api-type.const';

@Injectable({ providedIn: 'root' })
export class RmaUrlsService {
  public constructor(private readonly environment: Environment) {}

  apiUrl(value: string, apiType: RmaApiUrlType = 'Rma'): string {
    const cleanedPath = this.stripLeadingSlash(value);
    return `${this.getApiUrl(apiType)}/${cleanedPath}`;
  }

  public baseUrl(value: string): string {
    const cleanedPath = this.stripLeadingSlash(value);
    return this.formatUrl(cleanedPath, this.environment.baseUrl);
  }

  public cdnUrl(value: string): string {
    const cleanedPath = this.stripLeadingSlash(value);
    return this.formatUrl(cleanedPath, this.environment.assets.cdnUrl);
  }

  public illustrationUrl(value: string): string {
    const cleanedPath = this.stripLeadingSlash(value);
    return this.imageUrl(`illustrations/${cleanedPath}`);
  }

  public imageUrl(value: string): string {
    const cleanedPath = this.stripLeadingSlash(value);
    return this.staticAssetUrl(`/assets/images/${cleanedPath}`);
  }

  public staticAssetUrl(value: string): string {
    return this.formatUrl(value, this.environment.assets.staticUrl);
  }

  private formatUrl(value: string, host: string) {
    const url = new URL(value, host);
    return url.toString();
  }

  private stripLeadingSlash(value: string) {
    if (!value) {
      return value;
    }

    return value.replace(/^\//, '');
  }

  private getApiUrl(apiType: RmaApiUrlType) {
    const { reviewsApiUrl, socialApiUrl, billingApiUrl, surveyApiUrl, emailApiUrl, findMyAgentApiUrl, apiUrl } = this.environment.api;

    switch (apiType) {
      case 'Reviews':
        return reviewsApiUrl;
      case 'Social':
        return socialApiUrl;
      case 'Billing':
        return billingApiUrl;
      case 'Identity':
        return this.environment.identity.url;
      case 'Survey':
        return surveyApiUrl;
      case 'Email':
        return emailApiUrl;
      case 'FindMyAgent':
        return findMyAgentApiUrl;
      case 'Rma':
      default:
        return apiUrl;
    }
  }
}
