import { Pipe, PipeTransform } from '@angular/core';
import { RmaUrlsService } from '../rma-urls.service';

@Pipe({
  name: 'staticAssetUrl',
  standalone: true,
})
export class StaticAssetUrlPipe implements PipeTransform {
  public constructor(private readonly rmaUrls: RmaUrlsService) {}

  transform(value: string): string {
    return this.rmaUrls.staticAssetUrl(value);
  }
}
