import { EnvironmentProviders, LOCALE_ID, makeEnvironmentProviders } from '@angular/core';
import { Environment } from './domain';

export function provideRmaLocale(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: LOCALE_ID,
      deps: [Environment],
      useFactory: (env: Environment) => env.language.locale,
    },
  ]);
}
