"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
;
function cache(target, name, descriptor) {
  var getter = descriptor.get;
  if (!getter) throw new TypeError("Getter property descriptor expected");
  descriptor.get = function () {
    var value = getter.call(this);
    Object.defineProperty(this, name, {
      configurable: descriptor.configurable,
      enumerable: descriptor.enumerable,
      writable: false,
      value: value
    });
    return value;
  };
}
exports.cache = cache;
