import { Inject, Injectable } from '@angular/core';
import { IS_PRODUCTION, PlatformService } from '@rma/generic/util/environment';
import { LDClient, LDClientBase, LDOptions, basicLogger } from 'launchdarkly-js-client-sdk';
import { Observable, ReplaySubject, from } from 'rxjs';
import { mapTo, take } from 'rxjs/operators';
import { anonymousUser } from '../domain/anonymous-user.constant';
import { LAUNCH_DARKLY_INITIALISE, LaunchDarklyInitialise } from '../domain/launch-darkly-initialise.const';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyClient {
  private launchDarklyClient$ = new ReplaySubject<LDClientBase | null>(1);
  private launchDarklyClient!: LDClient;

  public constructor(
    @Inject(LAUNCH_DARKLY_INITIALISE) private readonly initialise: LaunchDarklyInitialise,
    private readonly platformService: PlatformService,
  ) {}

  public loadClient$(launchDarklyClientId: string, options: LDOptions): Observable<LDClientBase | null> {
    const config: LDOptions = {
      ...options,
      logger: basicLogger({ level: IS_PRODUCTION ? 'error' : 'warn' }),
      sendEvents: this.platformService.isPlatformBrowser,
    };

    const launchDarklyClient = this.initialise(launchDarklyClientId, anonymousUser, config);

    from(launchDarklyClient.waitForInitialization()).subscribe({
      complete: () => {
        this.launchDarklyClient = launchDarklyClient;
        this.launchDarklyClient$.next(launchDarklyClient);
      },
      error: (err) => {
        console.error(err);
        this.launchDarklyClient$.next(null); // will never be ready.
      },
    });

    return this.getClient$();
  }

  public getClient$(): Observable<LDClientBase | null> {
    return this.launchDarklyClient$.pipe(take(1));
  }

  public getClient(): LDClientBase | null {
    return this.launchDarklyClient;
  }

  public onLoad$(): Observable<boolean> {
    return this.launchDarklyClient$.pipe(mapTo(true), take(1));
  }
}
