export enum VideoProviderType {
  Youtube = 'Youtube',
  Vimeo = 'Vimeo',
}

export interface Video {
  code: string;
  provider: VideoProviderType;
}

export interface VideoOptions {
  autoplay: boolean;
  mute: boolean;
  loop: boolean;
}
