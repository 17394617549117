/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentProviders, Provider, StaticProvider, makeEnvironmentProviders } from '@angular/core';
import { Environment } from './domain';

// This only covers simple deep partial. If you need something more comprehensive, we should probably install type-fest
type SimpleDeepPartial<T> = T extends any[]
  ? T
  : T extends Record<string, any>
    ? {
        [P in keyof T]?: SimpleDeepPartial<T[P]>;
      }
    : T;

export function provideRmaEnvironment(env: Partial<Environment>): EnvironmentProviders {
  return makeEnvironmentProviders(provideRmaEnvironmentStatic(env));
}

export function provideRmaEnvironmentStatic(env: Partial<Environment>): StaticProvider[] {
  return [{ provide: Environment, useValue: env }];
}

// This in the future, we might add some defaults
export function provideRmaEnvironmentMock(env: SimpleDeepPartial<Environment>): Provider {
  return { provide: Environment, useValue: env };
}
